import {
  type HeadersFunction,
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from '@remix-run/node'
import {Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData,} from '@remix-run/react'
import {withSentry} from '@sentry/remix'
import {useEffect} from "react";
import TagManager from 'react-gtm-module';
import {HoneypotProvider} from 'remix-utils/honeypot/react'
import {i18nConfig, useChangeLanguage} from "#app/utils/i18n.ts";
import {i18next} from "#app/utils/i18next.server.ts";
import {GeneralErrorBoundary} from './components/error-boundary.tsx'
import {EpicProgress} from './components/progress-bar.tsx'
import {useToast} from './components/toaster.tsx'
import {EpicToaster} from './components/ui/sonner.tsx'
import {useTheme} from './routes/resources+/theme-switch.tsx'
import '@fontsource-variable/bricolage-grotesque';
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import {getTeamId, getUserId, logout} from './utils/auth.server.ts'
import {ClientHintCheck, getHints} from './utils/client-hints.tsx'
import {prisma} from './utils/db.server.ts'
import {getEnv} from './utils/env.server.ts'
import {honeypot} from './utils/honeypot.server.ts'
import {combineHeaders, getDomainUrl} from './utils/misc.tsx'
import {useNonce} from './utils/nonce-provider.ts'
import {getTheme, type Theme} from './utils/theme.server.ts'
import {makeTimings, time} from './utils/timing.server.ts'
import {getToast} from './utils/toast.server.ts'
import { type $Enums } from '@prisma/client';

export const links: LinksFunction = () => {
  return [
    // Preload CSS as a resource to avoid render blocking
    {rel: 'mask-icon', href: '/favicons/mask-icon.svg'},
    {
      rel: 'alternate icon',
      type: 'image/png',
      href: '/favicons/favicon-32x32.png',
    },
    {rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png'},
    {
      rel: 'manifest',
      href: '/site.webmanifest',
      crossOrigin: 'use-credentials',
    } as const, // necessary to make typescript happy
    //These should match the css preloads above to avoid css as render blocking resource
    {rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg'},
    {rel: 'stylesheet', href: tailwindStyleSheetUrl},
  ].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({data}) => {
  const title = `${data?.title ?? 'Error'} | Supermerchado`

  return [
    {title},
    {name: 'description', content: data ? data.description : ''},
  ]
}

export interface ExtendedUser {
  id: string,
  firstName: string | null,
  lastName: string | null,
  email: string,
  locale: string,
  image: { id: string; },
  comment: string | null,
  termsAccepted: Date | null,
  memberships: {
    id: string;
    default: boolean;
    team: { id: string; name: string };
    roles: { name: string; permissions: { entity: string; action: string; access: string; }[] }[]
  }[]
  roles: { name: string; permissions: { entity: string; action: string; access: string; }[]; }[] | undefined,
  team: { id: string, name: string } | undefined
}

export async function loader({request}: LoaderFunctionArgs) {
  let t = await i18next.getFixedT(request);
  const timings = makeTimings('root loader')
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root',
  })

  const user = userId
    ? await time(
      () =>
        prisma.user.findUniqueOrThrow({
          select: {
            id: true,
            firstName: true,
            lastName: true,
            email: true,
            locale: true,
            comment: true,
            image: {select: {id: true}},
            termsAccepted: true,
            memberships: {
              select: {
                id: true,
                default: true,
                team: {
                  select: {
                    id: true,
                    name: true,
                  },
                },
                roles: {
                  select: {
                    name: true,
                    permissions: {
                      select: {entity: true, action: true, access: true},
                    },
                  },
                },
              }
            },
          },
          where: {id: userId},
        }),
      {timings, type: 'find user', desc: 'find user in root'},
    )
    : null
  if (userId && !user) {
    console.info('something weird happened')
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({request, redirectTo: '/'})
  }
  const {toast, headers: toastHeaders} = await getToast(request)
  const honeyProps = honeypot.getInputProps()
  let locale = await i18next.getLocale(request)
  let notifications: ({ notification: { id: string; title: string; text: string | null; variant: $Enums.NotificationVariant; link: string | null; createdAt: Date; updatedAt: Date; }; } & { id: string; read: boolean; createdAt: Date; updatedAt: Date; userId: string; notificationId: string; })[] = []
  let notificationsCount = 0

  const extendedUser = user as ExtendedUser;

  if (userId && user) {
    const teamId = await getTeamId(request)
    extendedUser.team = extendedUser.memberships.find(c => c.team.id === teamId)?.team
    extendedUser.roles = extendedUser.memberships.find(c => c.team.id === teamId)?.roles
    if (extendedUser.locale) {
      locale = extendedUser.locale
    }
    notifications = await prisma.userNotification.findMany({
      where: {userId: userId},
      orderBy: {createdAt: 'desc'},
      take: 5,
      include: {
        notification: true,
      },
    })
    notificationsCount = await prisma.userNotification.count({
      where: {userId: userId, read: false},
    })
  }

  return json(
    {
      user: extendedUser,
      notifications,
      notificationsCount,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        locale,
        userPrefs: {
          theme: getTheme(request),
        },
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      title: t('app.title'),
      description: t('app.description'),
    },
    {
      headers: combineHeaders(
        {'Server-Timing': timings.toString()},
        toastHeaders,
      ),
    },
  )
}

export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: 'common',
}

export const headers: HeadersFunction = ({loaderHeaders}) => {
  return {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
  }
}

function Document({
                    children,
                    nonce,
                    theme = 'light',
                    locale = i18nConfig.fallbackLng,
                    env = {},
                    allowIndexing = true,
                  }: {
  children: React.ReactNode
  nonce: string
  theme?: Theme
  locale?: string
  env?: Record<string, string>
  allowIndexing?: boolean
}) {

  useEffect(() => {
    if (env.GOOGLE_TAG_MANAGER) {
      const tagManagerArgs = {
        gtmId: env.GOOGLE_TAG_MANAGER
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [env.GOOGLE_TAG_MANAGER]);


  return (
    <html lang={locale}>
    <head>
      <ClientHintCheck nonce={nonce}/>
      <Meta/>
      <meta charSet="utf-8"/>
      <meta name="viewport" content="width=device-width,initial-scale=1"/>
      {allowIndexing ? null : (
        <meta name="robots" content="noindex, nofollow"/>
      )}
      <Links/>
      <script nonce={nonce} src="https://assets.prd.heyflow.com/builder/widget/latest/webview.js"></script>
    </head>
    <body className="flex flex-col min-h-screen">
    {children}
    <script
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(env)}`,
      }}
    />
    <ScrollRestoration nonce={nonce}/>
    <Scripts nonce={nonce}/>
    </body>
    </html>
  )
}

function App() {
  const data = useLoaderData<typeof loader>()
  const nonce = useNonce()
  const theme = useTheme()
  const {locale} = data.requestInfo
  const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false'
  useToast(data.toast)
  useChangeLanguage(locale)

  return (
    <Document
      locale={locale}
      nonce={nonce}
      theme={theme}
      allowIndexing={allowIndexing}
      env={data.ENV}
    >
      <Outlet/>
      <EpicToaster closeButton position="top-center" theme={theme}/>
      <EpicProgress/>
    </Document>
  )
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>()
  return (
    <HoneypotProvider {...data.honeyProps}>
      <App/>
    </HoneypotProvider>
  )
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader, so we can access that
  const nonce = useNonce()

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run, so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary/>
    </Document>
  )
}
