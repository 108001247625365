import {parseWithZod} from '@conform-to/zod'
import {invariantResponse} from '@epic-web/invariant'
import {json, type ActionFunctionArgs} from '@remix-run/node'
import {useFetcher, useFetchers} from '@remix-run/react'
import {LaptopIcon, MoonIcon, SunIcon} from "lucide-react";
import {z} from 'zod'
import {
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSubContent
} from "#app/components/ui/dropdown-menu.tsx";
import {useHints} from '#app/utils/client-hints.tsx'
import {useRequestInfo} from '#app/utils/request-info.ts'
import {type Theme, setTheme} from '#app/utils/theme.server.ts'

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark']),
})

export async function action({request}: ActionFunctionArgs) {
  const formData = await request.formData()
  const submission = parseWithZod(formData, {
    schema: ThemeFormSchema,
  })

  invariantResponse(submission.status === 'success', 'Invalid theme received')

  const {theme} = submission.value

  const responseInit = {
    headers: {'set-cookie': setTheme(theme)},
  }
  return json({result: submission.reply()}, responseInit)
}

export function ThemeSwitch({
                              userPreference,
                            }: {
  userPreference?: Theme | null
}) {
  const fetcher = useFetcher<typeof action>()
  const optimisticMode = useOptimisticThemeMode()
  const mode = optimisticMode ?? userPreference ?? 'system'
  const modeLabel = {
    light: (
      <>
        <SunIcon className="w-4 h-4 mr-2"/>
        <span>Light</span>
      </>
    ),
    dark: (
      <>
        <MoonIcon className="w-4 h-4 mr-2"/>
        <span>Dark</span>
      </>
    ),
    system: (
      <>
        <LaptopIcon className="w-4 h-4 mr-2"/>
        <span>System</span>
      </>
    ),
  }

  const onChange = (value: string) => {
    fetcher.submit({theme: value}, {
      method: 'POST',
      action: '/resources/theme-switch'
    })
  }

  return (
    <DropdownMenuPortal>
      <DropdownMenuSubContent>
        <DropdownMenuRadioGroup
          value={mode as string}
          onValueChange={onChange}
        >
          <DropdownMenuRadioItem value={'light'}>
            {modeLabel.light}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={'dark'}>
            {modeLabel.dark}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={'system'}>
            {modeLabel.system}
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuSubContent>
    </DropdownMenuPortal>
  )
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers()
  const themeFetcher = fetchers.find(
    f => f.formAction === '/resources/theme-switch',
  )

  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema,
    })

    if (submission.status === 'success') {
      return submission.value.theme
    }
  }
}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints()
  const requestInfo = useRequestInfo()
  const optimisticMode = useOptimisticThemeMode()
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode
  }
  return requestInfo.userPrefs.theme ?? hints.theme
}
